import './gallery.css';
import { GallerySlider } from '../Components/GallerySlider';
import { Link } from 'react-router-dom';
import { useMenu, useMenuUpdate } from '../MenuContext';
import { FaFacebook } from 'react-icons/fa';

export function Gallery() {

    const menuUpdate = useMenuUpdate();

    return (
        <main className="GalleryWrapper">
                <section className="GalleryWrapper-ContentWrapper-GallerySection">
                    <div className="GalleryWrapper-ContentWrapper-GallerySection-ExteriorWrapper">
                        <h2 className="GalleryWrapper-ContentWrapper-GallerySection-ExteriorWrapper-Title">GALLERY</h2>
                        <div className="GalleryWrapper-ContentWrapper-GallerySection-ExteriorWrapper-GallerySliderWrapper">
                            <GallerySlider />
                        </div>
                    </div>
                    <div className="GalleryWrapper-ContentWrapper-GallerySection-ButtonWrapper">
                        <Link to="/contact"><button onClick={e => menuUpdate("Contact")} className="GalleryWrapper-ContentWrapper-ScheduleSection-ScheduleButton">SCHEDULE A FREE ESTIMATE</button></Link>
                    </div>
                </section>
            <footer className="GalleryWrapper-PageFooter">
                <a href="https://www.facebook.com/CooksPaintingCo/"><FaFacebook className="FB-Icon"></FaFacebook></a>
            </footer>
        </main>
    );
}

/*
                <section className="GalleryWrapper-ContentWrapper-TitleSection">
                    <div className="GalleryWrapper-ContentWrapper-TitleSection-ContentWrapper">
                        <h2 className="GalleryWrapper-ContentWrapper-TitleSection-ContentWrapper-Title fadein">
                            There is a reason our clients trust us. We deliver high quality work and have the proof to show it.
                            Check out some of our past projects below!
                        </h2>
                    </div>
                </section>
*/