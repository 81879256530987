import './GallerySlider.css';
import { useState } from 'react';
import Slider from "react-slick";
import ii1 from '../assets/internalimage1.jpg';
import ii2 from '../assets/internalimage2.jpg';
import ii3 from '../assets/internalimage3.jpg';
import ii4 from '../assets/internalimage4.jpg';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { GalleryData } from './GalleryData';

const images = GalleryData;

export function GallerySlider() {
    
    const NextArrow = ({onClick}) => {
        return (
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight />
            </div>
        );
    };
    
    const PrevArrow = ({onClick}) => {
        return (
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = useState(0);

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next)
    };

    return (
        <div className="GallerySliderWrapper">
            <Slider {...settings}>
                {images.map((img, idx) => (
                    <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
                        <img src={img} alt={img} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}