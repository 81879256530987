export const Pathnames = {
    Home: "/home",
    Gallery: "/gallery",
    Contact: "/contact"
};

export const Pagenames = {
    Home: "Home",
    Gallery: "Gallery",
    Contact: "Contact"
};