import e1 from '../assets/ex/e1.jpg';
import e2 from '../assets/ex/e2.jpg';
import e3 from '../assets/ex/e3.jpg';
import e4 from '../assets/ex/e4.jpg';
import e5 from '../assets/ex/e5.jpg';
import e6 from '../assets/ex/e6.jpg';
import e7 from '../assets/ex/e7.jpg';
import e8 from '../assets/ex/e8.jpg';
import e9 from '../assets/ex/e9.jpg';
import e10 from '../assets/ex/e10.jpg';
import e11 from '../assets/ex/e11.jpeg';
import e12 from '../assets/ex/e12.jpeg';
import e13 from '../assets/ex/e13.jpeg';
import e14 from '../assets/ex/e14.jpg';
import e15 from '../assets/ex/e15.jpg';
import e16 from '../assets/ex/e16.jpg';

export const GalleryData = [
    e1,
    e2,
    e3,
    e4,
    e5,
    e6,
    e7,
    e8,
    e9,
    e10,
    e11,
    e12,
    e13,
    e14,
    e15,
    e16
];