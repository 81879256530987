import './home.css';
import { useState, useEffect, Fragment } from 'react';
import { testimonialData } from '../models/testimonials';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { FaFacebook } from 'react-icons/fa';

let index = 1;

export function Home() {
    const aTestimonial = testimonialData[0];

    const [isVisible, setIsVisible] = useState(false);
    const [isServicesVisible, setIsServicesVisible] = useState(false);

    const listenToScroll = () => {
        let heightToShow = window.innerHeight;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll >= (heightToShow - 200) && !isVisible) {
            setIsVisible(true);
        }

        if (winScroll >= heightToShow + 200) {
            setIsServicesVisible(true);
        }
    };

    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
            window.removeEventListener("scroll", listenToScroll); 
    }, [])

    useEffect(() => {

        const interval = setInterval(() => {
            const aTestimonial = testimonialData[index];

            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-ImgWrapper")[0].classList.remove("fadeIn");
            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-ImgWrapper")[0].classList.add("fadeOut");
            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4")[0].classList.remove("fadeIn");
            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4")[0].classList.add("fadeOut");
            for(var i = 0; i < document.getElementsByClassName("checked").length; i++){
                document.getElementsByClassName("checked")[i].classList.remove("fadeIn");
                document.getElementsByClassName("checked")[i].classList.add("fadeOut");
            }
            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5")[0].classList.remove("fadeIn");
            document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5")[0].classList.add("fadeOut");
            setTimeout(() => {
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-img")[0].src = aTestimonial.image;
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4")[0].innerHTML = aTestimonial.name;
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5")[0].innerHTML = aTestimonial.description;

                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-ImgWrapper")[0].classList.remove("fadeOut");
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-ImgWrapper")[0].classList.add("fadeIn");
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4")[0].classList.remove("fadeOut");
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4")[0].classList.add("fadeIn");
                for(var i = 0; i < document.getElementsByClassName("checked").length; i++){
                    document.getElementsByClassName("checked")[i].classList.remove("fadeOut");
                    document.getElementsByClassName("checked")[i].classList.add("fadeIn");
                }
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5")[0].classList.remove("fadeOut");
                document.getElementsByClassName("HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5")[0].classList.add("fadeIn");
            }, 1000);
            
            index = index === testimonialData.length - 1 ? 0 : index + 1
         }, 8000); 
     
         return () => {
             clearInterval(interval);
         };
     }, []);

     
    return (
        <main className="HomeWrapper">
            <section className="HomeWrapper-TopSection">
                <div className="HomeWrapper-TopSection-ImageBackground">
                    <div className="HomeWrapper-TopSection-ImageBackground-ContentWrapper">
                        <h1 className="HomeWrapper-TopSection-ImageBackground-ContentWrapper-ShadedTitle fadein">SCHEDULE A FREE ESTIMATE</h1>
                        <h2 className="HomeWrapper-TopSection-ImageBackground-ContentWrapper-SecondaryTitle fadein">PROUDLY SERVING GENESEE, SHIAWASSEE, OAKLAND, AND LAPEER COUNTIES</h2>
                    </div>
                </div>
            </section>
            <section className='HomeWrapper-MiddleSection'>
                <div className='HomeWrapper-MiddleSection-Background-AboutWrapper'>
                    <div className='HomeWrapper-MiddleSection-Background-AboutWrapper-ContentWrapper'>
                        {   isVisible &&
                            <h1 className='HomeWrapper-MiddleSection-Background-AboutWrapper-ContentWrapper-Title fadein'>
                                Who Are We?
                            </h1>
                        }
                        {   isVisible &&
                            <h3 className='HomeWrapper-MiddleSection-Background-AboutWrapper-ContentWrapper-Text fadein'>
                                You have peace of mind knowing that the business owner is in your home, start to finish. CPC strives to provide exceptional service to our customers, 
                                from free, no-obligation estimates and site prep to meticulous finishing touches on your space. You can expect high-quality products and a beautiful home 
                                transformation when you book with CPC. 
                            </h3>
                        }
                        {   isVisible &&
                            <a className="fadein" href="https://www.facebook.com/CooksPaintingCo/"><FaFacebook className="FB-Icon"></FaFacebook></a>
                        }
                    </div>
                </div>
            </section>
            <section className="HomeWrapper-BottomSection">
                <div className="HomeWrapper-BottomSection-Background-ServicesWrapper">
                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper">
                        { isServicesVisible &&
                            <Fragment>
                                <h2 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Title fadein">SERVICES</h2>
                                <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Frame">
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Image1 Image-Slider">
                                        <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Cover">
                                            <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Text">INTERIOR & EXTERIOR</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Frame">
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Image2 Image-Slider">
                                        <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Cover">
                                            <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Text">CABINETS & VANITIES</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Frame">
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Image3 Image-Slider">
                                        <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Cover">
                                            <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Text">WINDOWS & DOORS</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Frame">
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Image4 Image-Slider">
                                        <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Cover">
                                            <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-Content-Text">DECKS & PATIOS</h3>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                <div className="HomeWrapper-TestimonialsWrapper">
                    <div className="HomeWrapper-TestimonialsWrapper-ContentWrapper">
                        <h3 className="HomeWrapper-TestimonialsWrapper-ContentWrapper-TestimonialsTitle">DONT JUST TAKE OUR WORD FOR IT</h3>
                        <div className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel">
                            <div className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-ImgWrapper">
                                <img className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-img" src={aTestimonial.image} width="150px" height="150px"></img>
                            </div>
                            <h4 className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h4">{aTestimonial.name}</h4>
                            <div className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-RateWrapper">
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                                <span class="fa fa-star checked"></span>
                            </div>
                            <h5 className="HomeWrapper-TestimonialsWrapper-ContentWrapper-ContentCarousel-h5">{aTestimonial.description}</h5>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

/*
<div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList">
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service">
                                        <img className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-img fadein" src={require("../assets/roller.jpg")} width="100px" height="100px"></img>
                                        <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-h3 fadein">INTERIOR & EXTERIOR</h3>
                                    </div>
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service">
                                        <img className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-img fadein" src={require("../assets/bucket.webp")} width="100px" height="100px"></img>
                                        <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-h3 fadein">CABINETS & VANITIES</h3>
                                    </div>
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service">
                                        <img className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-img fadein" src={require("../assets/brush.webp")} width="100px" height="100px"></img>
                                        <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-h3 fadein">WINDOWS & DOORS</h3>
                                    </div>
                                    <div className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service">
                                        <img className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-img fadein" src={require("../assets/brush.webp")} width="100px" height="100px"></img>
                                        <h3 className="HomeWrapper-BottomSection-Background-ServicesWrapper-ContentWrapper-ServiceList-Service-h3 fadein">DECKS & PATIOS</h3>
                                    </div>
                                </div>
*/