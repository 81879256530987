import { FaFacebook, FaSlideshare } from 'react-icons/fa';
import './contact.css';
import { send } from '@emailjs/browser'
import { useState } from 'react';
import LoadingSpinner from '../Components/LoadingSpinner/LoadingSpinner';
import { ConfirmModal } from '../Components/Modals/ConfirmModal';
import { Fragment } from 'react/cjs/react.production.min';

export function Contact() {

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [showModal, setShowModal] = useState(false); 
    const [modalText, setModalText] = useState("");
    const [spinnerDisplay, setSpinnerDisplay] = useState("none");
    const nameChange = e => setName(e.target.value);
    const phoneChange = e => setPhoneNumber(e.target.value);
    const descriptionChange = e => setProjectDescription(e.target.value);
    const checkComponentState = () => {
        let errorQueue = [];
        if (!name || name === "") errorQueue.push("Name cannot be empty");
        if (!phoneNumber.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) errorQueue.push("Invalid phone number");
        if (!projectDescription || projectDescription === "") errorQueue.push("Project Description cannot be empty");

        return errorQueue;
    };

    const closeModal = e => {
        setModalText("");
        setShowModal(false);
    };

    const sendEmail = async (e) => {
        setSpinnerDisplay("flex");
        let errorQueue = checkComponentState();
        if (!errorQueue.length){
            const result  = await send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                {
                    Name: name,
                    Phone_Number: phoneNumber,
                    Project_Description: projectDescription
                },
                process.env.REACT_APP_USER_ID
            );
        
            if (result.status === 200) {
                setName("");
                setPhoneNumber("");
                setProjectDescription("");
                setModalText("You're quote request was submitted! We will contact you once it is processed. Thank you!");
                setShowModal(true);
            } else {
                setModalText("An unexpected problem occured when submitting quote request. Please try again in a little bit.");
                setShowModal(true);
            }
        } else {
            let errorMessage = `Please fix the following issues before submitting quote request: ${errorQueue.join(", ")}`;
            setModalText(errorMessage);
            setShowModal(true);
        }
        setSpinnerDisplay("none");
    };

    return (
        <Fragment>
            { showModal ?
            <ConfirmModal close={closeModal} text={modalText}/> :
            ""
            }
            < LoadingSpinner show={spinnerDisplay}/>
        <main className="ContactWrapper">
            <div className="ContactWrapper-TopBar"></div>
            <div className="ContactWrapper-ContentWrapper">
                <div className="ContactWrapper-ContentWrapper-TitleWrapper">
                    <h2 className="ContactWrapper-ContentWrapper-TitleWrapper-h2">Schedule Free Quote</h2>
                </div>
                <div className="ContactWrapper-ContentWrapper-FormWrapper">
                    <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper">
                        <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn">
                            <h3 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-Title">Request Estimate</h3>
                            <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea">
                                <h4 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-h4">Name</h4>
                                <input id='FormWrapper-Name' className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-input" onChange={nameChange} value={name}></input>
                            </div>
                            <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea">
                                <h4 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-h4">Phone Number</h4>
                                <input id='FormWrapper-PhoneNumber' className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-input" onChange={phoneChange} value={phoneNumber}></input>
                            </div>
                            <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea">
                                <h4 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-h4">Project Description</h4>
                                <textarea id='FormWrapper-ProjectDescription' className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea-input" onChange={descriptionChange} style={{height: "80px"}} value={projectDescription}></textarea>
                            </div>
                            <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-InputArea">
                                <button className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-LeftColumn-Submit" onClick={sendEmail}>Submit</button>
                            </div>
                        </div>
                        <div className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-RightColumn">
                            <h3 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-RightColumn-Questions">Have Questions?</h3>
                            <h4 className="ContactWrapper-ContentWrapper-FormWrapper-ColumnWrapper-RightColumn-ContactInfo">call or text +1 (810) 875-5009</h4>
                        </div>
                    </div>
                    <footer className="ContactWrapper-ContentWrapper-FormWrapper-Footer">
                        <h2 className="ContactWrapper-ContentWrapper-FormWrapper-Footer-Content">FOLLOW US ON FACEBOOK @CooksPaintingCo</h2>
                    </footer>
                </div>
            </div>
            <footer className="ContactWrapper-PageFooter">
                <a href="https://www.facebook.com/CooksPaintingCo/"><FaFacebook className="FB-Icon"></FaFacebook></a>
            </footer>
        </main>
        </Fragment>
    );
}