import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useMenu, useMenuUpdate } from './MenuContext'; 
import { Navigate } from 'react-router-dom';
import { init } from '@emailjs/browser';

function App() {

  init(process.env.REACT_APP_USER_ID);

  const menuUpdate = useMenuUpdate();
  const menuItem = useMenu();

  const location = useLocation();
  menuUpdate(location.pathname);

  if(location.pathname === "/") {
    return <Navigate to="/home"/>
  }

  return (  
      <div className="App">
        <div className="MainHeader">
          <div className="MainHeader-LogoWrapper">
            <img className="MainHeader-LogoWrapper-Logo" src={require("./assets/logo.PNG")}></img>
          </div>
          <div className="MainHeader-ButtonWrapper">
            <Link onClick={e => menuUpdate("Home")} to="/home"><button id="Button-Home" className={menuItem === "Home" ? "MainHeader-ButtonWrapper-Button MainHeader-ButtonWrapper-Button-Selected" : "MainHeader-ButtonWrapper-Button"}><b>Home</b></button></Link>
            <Link onClick={e => menuUpdate("Gallery")} to="/gallery"><button id="Button-Gallery" className={menuItem === "Gallery" ? "MainHeader-ButtonWrapper-Button MainHeader-ButtonWrapper-Button-Selected" : "MainHeader-ButtonWrapper-Button"}><b>Gallery</b></button></Link>
            <Link onClick={e => menuUpdate("Contact")} to="/contact"><button id="Button-Contact" className={menuItem === "Contact" ? "MainHeader-ButtonWrapper-Button MainHeader-ButtonWrapper-Button-Selected" : "MainHeader-ButtonWrapper-Button"}><b>Contact</b></button></Link>
          </div>
        </div>
        <Outlet />
      </div>
  );
}

export default App;
