import React, { useContext, useState } from 'react';
import { Pathnames, Pagenames } from './pathnames';

const MenuContext = React.createContext();
const MenuUpdateContext = React.createContext();

export function useMenu() {
    return useContext(MenuContext);
}

export function useMenuUpdate() {
    return useContext(MenuUpdateContext);
}

export function MenuProvider({ children }) {
    const [selected, setSelected] = useState("");
    function changeSelected(path) {
        setSelected(prevPage => {
            switch(path){
                case Pathnames.Home:
                    return Pagenames.Home;
                case Pathnames.Gallery:
                    return Pagenames.Gallery;
                case Pathnames.Contact:
                    return Pagenames.Contact;
                default:
                    return Pagenames.Home;
            }
        });
    }

    return (
        <MenuContext.Provider value={selected}>
            <MenuUpdateContext.Provider value={changeSelected}>
                {children}
            </MenuUpdateContext.Provider>
        </MenuContext.Provider>
    );
}
