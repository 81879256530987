import './ConfirmModal.css';

export function ConfirmModal(props) {

    return (
        <div className="ConfirmModalWrapper" id="ConfirmModalWrapper">
            <div className="ConfirmModalWrapper-ContentWrapper">
                <p className="ConfirmModalWrapper-ContentWrapper-Text" id="ConfirmModalWrapper-ContentWrapper-Text">{props.text}</p>
                <div className="ConfirmModalWrapper-ContentWrapper-ButtonWrapper">
                    <button className="ConfirmModalWrapper-ContentWrapper-ButtonWrapper-Button" onClick={props.close}>Ok</button>
                </div>
            </div>
        </div>
    );
}