import ac from '../assets/testimonials/ac.png';
import sz from '../assets/testimonials/sz.png';
import def from '../assets/testimonials/default.png';

export const testimonialData = [
    {
        image: def,
        name: "Hunter Balsbaugh",
        description: "Gage did a phenomenal job!! Very fair pricing as well and very clean with his work!"
    },
    {
        image: def,
        name: "Matt Misner",
        description: "Gage painted my daughter’s home after some remodeling. His repairs to the plaster and other dry walled areas were well done. His priming and painting was spot on and finished promptly. He’s an efficient worker and very tidy and meticulous. Gage is a Great guy and I am happy to know him. Thank you Gage!"
    },
    {
        image: def,
        name: "Brittany Seavey",
        description: "I hired Cooks Painting Co last summer to paint before we listed our house for sale. Not only was he professional and on time his work was flawless. With Gage’s help we were able to sell our house in four days. I can’t say enough great things about him and will absolutely be using him for all of my painting needs."
    },
    {
        image: ac,
        name: "Amanda Campbell",
        description: "Cooks Painting does incredible work at a great price. They transformed our entry way & living room by painting very challenging areas that we wouldn't have been able to do ourselves. We look forward to hiring them again!"
    },
    {
        image: def,
        name: "Christle Sneed",
        description: "We were very happy with the update to our old brick on the front of our house! They communicated well and did a great job!"
    },
    {
        image: sz,
        name: "Stephanie Zawacki",
        description: "Very professional and he does an amazing job! Painting can be so tedious and most people don’t enjoy it. So much easier to hire someone to do the work for you especially when you can trust them."
    },
    {
        image: def,
        name: "Mary Herbst",
        description: "He did a great job. Everything we asked of him was done without question! We hope to have him back soon to do more."
    },
    {
        image: def,
        name: "Glenn Kittel II",
        description: "Very professional and responsive. Great quality work. My family will continue to use their services."
    },
    {
        image: def,
        name: "Anonymous",
        description: "Very professional and clean. Uses very good quality products."
    }
];